import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import Vue from 'vue';
import { currencyFormat } from '@/vue-app/utils/currency';
import { parseCurrencyToNumber } from '@/vue-app/utils/parse-currency-to-number';

// Application
import GetPersonalInfoQuery
  from '@/modules/flagship/investor-profile/personal-info/application/queries/get-personal-info-query';
import CalculateProjectionCommand
  from '@/modules/flagship/retirement-forecast-calculation/application/commands/calculate-projection-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';

// Domain
import {
  ProjectionCalculationDto,
} from '@/modules/flagship/retirement-forecast-calculation/domain/dtos/projection-calculation-dto';
import {
  ProjectionCalculatedEntity,
} from '@/modules/flagship/retirement-forecast-calculation/domain/entities/projection-calculated-entity';
import {
  CreateInvestorGoalStateManager,
} from '@/modules/flagship/investor-goal/investor_goal/domain/state/create-investor-goal-state-manager';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class RetirementFundProjectionViewModel {
  @Inject(TYPES.GET_PERSONAL_INFO_QUERY)
  private readonly get_personal_info_query!: GetPersonalInfoQuery;

  @Inject(TYPES.CALCULATE_PROJECTION_COMMAND)
  private readonly calculate_projection!: CalculateProjectionCommand;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.CREATE_INVESTOR_GOAL_STATE_MANAGER)
  readonly create_investor_goal_state_manager!: CreateInvestorGoalStateManager;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship_profiling_projection';

  SLIDER_MAX_VALUE = 75;

  SLIDER_MIN_VALUE = 55;

  is_loading = false;

  monthly_payment = '';

  retirement_years = 0;

  accumulated_amount = '';

  monthly_pension_amount = '';

  initial_amount = '0.0';

  monthly_confirmed_amount = '';

  chart_series: Array<any> = [];

  investor_profile_id = '';

  issued_age = 0;

  chart_options = {
    chart: {
      type: 'area',
      height: 350,
      stacked: true,
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    yaxis: {
      labels: {
        formatter(value: number) {
          return currencyFormat(value);
        },
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
        position: 'topRight',
      },
    },
  };

  private forecast_calculation: ProjectionCalculatedEntity = {
    retirement_chart_report: {},
    retirement_calculation_data: {
      retirement_range_adjusted: 0,
      monthly_required_amount: 0,
      pension_range_adjusted: 0,
      interest_rate: 0,
      initial_amount: 0,
      accumulated_amount: 0,
      monthly_pension_adjusted: 0,
    },
    retirement_forecast: {},
  }

  investor_goal_state = this.create_investor_goal_state_manager.state;

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string, options?: Record<string, string | number>) => {
    if (options) {
      return i18n.tc(`${this.i18n_namespace}.${value}`, 0, options) as string;
    }
    return i18n.t(`${this.i18n_namespace}.${value}`) as string;
  };

  initialize = async () => {
    await this.getInvestorProfile();
    await this.getCurrentAge();
    await this.getRetirementForecastCalculation();
  }

  get amount_currency_format() {
    return Math.trunc(Number(this.accumulated_amount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  getCurrentAge = async () => {
    const { person } = await this.get_personal_info_query.execute(this.investor_profile_id);
    this.issued_age = person.current_age;
  }

  getInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      this.investor_profile_id = investor_profile.id;
      const surplus_amount = (investor_profile.net_monthly_income
        - investor_profile.net_monthly_expenses
      );

      const valid_surplus_amount = surplus_amount > 0 ? surplus_amount : investor_profile
        .net_monthly_income * 0.05;

      this.monthly_confirmed_amount = valid_surplus_amount.toString();
      this.monthly_payment = valid_surplus_amount.toString();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investor_profile'));
    }
  };

  getRetirementForecastCalculation = async () => {
    try {
      const payload: ProjectionCalculationDto = {
        issued_age: this.issued_age,
        initial_amount: parseCurrencyToNumber(this.initial_amount).toString(),
        retirement_age_adjusted: this.retirement_years.toString(),
        monthly_required_amount: parseCurrencyToNumber(this.monthly_confirmed_amount).toString(),
      };
      this.forecast_calculation = await this.calculate_projection.internalExecute(payload);
      this.projectGraph(this.forecast_calculation);
      this.updateProjection(this.forecast_calculation);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_retirement_forecast_calculation'));
    }
  };

  updateProjection = (forecast_calculation: ProjectionCalculatedEntity) => {
    this.SLIDER_MIN_VALUE = this.issued_age > this.SLIDER_MIN_VALUE
      ? this.issued_age + 1 : this.SLIDER_MIN_VALUE;
    this.accumulated_amount = forecast_calculation.retirement_calculation_data.accumulated_amount
      .toString();
    this.monthly_pension_amount = Math.trunc(
      forecast_calculation.retirement_calculation_data.monthly_pension_adjusted,
    ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    this.monthly_confirmed_amount = forecast_calculation
      .retirement_calculation_data.monthly_required_amount.toString();
  }

  projectGraph = (series: ProjectionCalculatedEntity) => {
    const retirement_chart_report: Array<any> = [];
    this.chart_series = [];

    Object.entries(series.retirement_chart_report).forEach((serie: any) => {
      retirement_chart_report.push({
        x: (serie[0] === 0) ? `${i18n.tc(`${this.i18n_namespace}.today`)}`
          : `${serie[0]} ${i18n.tc(`${this.i18n_namespace}.years`)}`,
        y: serie[1],
      });
    });

    this.chart_series.push({
      name: `${i18n.tc(`${this.i18n_namespace}.monthly_savings`)}`,
      data: retirement_chart_report,
    });
  }

  monthlyConfirmedAmountChange = () => {
    this.monthly_payment = parseCurrencyToNumber(this.monthly_confirmed_amount).toString();
  }

  setState = () => {
    this.investor_goal_state.investor_goal.initial_amount = parseCurrencyToNumber(
      this.initial_amount,
    );
    this.investor_goal_state.investor_goal.monthly_required_amount = parseCurrencyToNumber(
      this.monthly_confirmed_amount,
    );
    this.investor_goal_state.retirement_fund.issued_age = this.issued_age;
    this.investor_goal_state.retirement_fund.retirement_age_adjusted = this.retirement_years;
  }

  updateInformation = async () => {
    try {
      this.is_loading = true;
      this.setState();
      this.view.$emit('prevStep');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_retirement_investor_goal'));
    } finally {
      this.is_loading = false;
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }
}
