




































































































import { Component, Vue } from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';

import RetirementFundProjectionViewModel
  from '@/vue-app/view-models/components/goals-dashboard/create-goal/retirement-fund/retirement-fund-projection-view-model';

@Component({
  name: 'RetirementFundProjection',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
  filters: { currencyFormat },
})
export default class RetirementFundProjection extends Vue {
  flagship_projection_view_model = Vue.observable(
    new RetirementFundProjectionViewModel(this),
  );

  created() {
    this.flagship_projection_view_model.initialize();
  }
}
